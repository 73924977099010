<template>
  <VRow>
    <VCol>
      <div class="tt-text-title-2 mb-3 d-flex justify-space-between align-center">
        <span
          class="tt-text-title-2"
          data-test="profile-email-title"
        >{{ $t('profile-email.email') }}</span>
        <TTBtn
          v-if="edit && !type"
          color="tt-ghost"
          small
          plain
          data-test-label="profile-email-btn-edit"
          @click="onEdit"
        >
          <VIcon>
            far fa-pen
          </VIcon>
        </TTBtn>
      </div>
      <template v-if="!type">
        <span
          data-test-label="profile-email-text"
          class="tt-text-body-2 text-pre-line"
        >
          {{ email }}
        </span>
      </template>
      <template v-else-if="type === TYPES.inputEmail">
        <VRow justify="space-between">
          <VCol>
            <VForm
              v-model="formValidEmail"
            >
              <TTTextField
                v-model="inputEmail"
                placeholder="example@example.ru"
                maxlength="250"
                :rules="rulesEmail"
                :error-messages="errorMessageEmail"
                large
                data-test-label="profile-email-textfield"
                @input="() => errorMessageEmail = null"
              />
            </VForm>
          </VCol>
          <VCol
            cols="12"
            sm="auto"
          >
            <TTBtn
              large
              :block="isMobile"
              data-test-label="profile-email-btn-cancel"
              color="tt-secondary"
              @click="cancelInput"
            >
              {{ $t('cancel') }}
            </TTBtn>
          </VCol>
          <VCol
            cols="12"
            sm="auto"
          >
            <TTBtn
              large
              :block="isMobile"
              :loading="loadingSubmitEmail"
              :disabled="!formValidEmail || loadingSubmitEmail || notUniqEmail"
              data-test-label="profile-email-btn-update"
              @click="updateEmail"
            >
              {{ $t('confirm') }}
            </TTBtn>
          </VCol>
        </VRow>
      </template>
      <template v-else-if="type === TYPES.inputCode">
        <VRow justify="space-between">
          <VCol>
            <VForm
              v-model="formValidCode"
            >
              <TTTextField
                v-model="inputCode"
                large
                maxlength="6"
                :rules="rulesCode"
                persistent-hint
                data-test-label="profile-email-code-textfield"
                @input="() => errorMessageCode = null"
              />
              <div
                class="tt-text-body-2 mb-2 mt-2"
                data-test="profile-email-text-send"
              >
                {{ $t('profile-email.email_code_send') }} {{ inputEmail }}
                <a
                  href="#"
                  class="text-decoration-none tt-text-body-2"
                  data-test="profile-email-link-change"
                  @click.prevent="goToInputEmail"
                >{{ $t('change') }}</a>
              </div>
              <div
                v-if="errorMessageCode"
                class="tt-text-body-2 tt-light-red--text"
                data-test="profile-email-code-textfield-error"
              >
                {{ errorMessageCode }}
              </div>
              <template v-else>
                <div
                  v-if="delaySecond > 0"
                  class="tt-text-body-2 tt-light-mono-46--text"
                  data-test="profile-email-code-delay"
                >
                  {{ $t("code_delay") }}
                  <span class="text-no-wrap">{{ $tc("seconds", delaySecond) }}</span>
                </div>
                <template v-else>
                  <div
                    class="tt-text-body-2 tt-light-mono-46--text"
                    data-test="profile-email-no-send-code"
                  >
                    {{ $t('profile-email.no_send_code') }}
                  </div>
                  <a
                    href="#"
                    class="text-decoration-none tt-text-body-2"
                    data-test="profile-email-link-resend-code"
                    @click.prevent="resendEmailCode"
                  >{{ $t('profile-email.resend_code') }}</a>
                </template>
              </template>
            </VForm>
          </VCol>
          <VCol cols="auto">
            <TTBtn
              large
              :loading="loadingSubmitCode"
              :disabled="!formValidCode || loadingSubmitCode"
              data-test-label="profile-email-code-btn-update"
              width="152"
              @click="updateCode"
            >
              {{ $t('submit') }}
            </TTBtn>
          </VCol>
        </VRow>
      </template>
    </VCol>
  </VRow>
</template>

<script>
import {
  EMAIL_REGEX,
} from '@/helpers/constants';
import * as snamiApi from '@/services/api/snami';

const TYPES = {
  inputCode: 'input_code',
  inputEmail: 'input_email',
};

export default {
  name: 'ProfileEmail',
  props: {
    email: {
      type: String,
      default: null,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    step: {
      type: String,
      validator: (v) => Object.values(TYPES).includes(v),
      default: null,
    },
  },
  data() {
    return {
      inputEmail: this.email,
      inputCode: '',
      type: this.step,
      formValidEmail: false,
      formValidCode: false,
      errorMessageEmail: null,
      errorMessageCode: null,
      loadingSubmitCode: false,
      loadingSubmitEmail: false,
      delaySecond: 0,
      TYPES,
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    rulesEmail() {
      return [(v) => EMAIL_REGEX.test(v) || this.$t('profile-email.error_email')];
    },
    rulesCode() {
      return [(v) => !!v];
    },
    notUniqEmail() {
      return this.email === this.inputEmail;
    },
  },
  watch: {
    delaySecond() {
      if (this.delaySecond === 0) {
        clearInterval(this.timer);
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    onEdit() {
      this.type = TYPES.inputEmail;
    },
    async updateEmail() {
      try {
        this.loadingSubmitEmail = true;
        await snamiApi.staffEmailUpdate(this.inputEmail);
        this.$emit('update:email', this.inputEmail);
        this.type = TYPES.inputCode;
        this.startTimer();
      } catch (e) {
        const { error } = e.response?.data;
        const snackMessage = error?.data?.find((v) => v.key === 'snack_message');
        this.errorMessageEmail = snackMessage?.value || error?.message || this.$t('request_error');
      } finally {
        this.loadingSubmitEmail = false;
      }
    },
    async updateCode() {
      try {
        this.loadingSubmitCode = true;
        await snamiApi.staffEmailConfirmKey(this.inputCode);
        this.$emit('update:email', this.inputEmail);
        this.type = null;
        this.inputCode = '';
        this.clearErrors();
      } catch (e) {
        const { error } = e.response?.data;
        const snackMessage = error?.data?.find((v) => v.key === 'snack_message');
        this.errorMessageCode = snackMessage?.value || error?.message || this.$t('request_error');
      } finally {
        this.loadingSubmitCode = false;
      }
    },
    async resendEmailCode() {
      this.errorMessageCode = null;
      this.inputCode = '';
      this.loadingSubmitCode = true;
      await this.updateEmail();
      this.loadingSubmitCode = false;
    },
    goToInputEmail() {
      this.type = TYPES.inputEmail;
      this.inputCode = '';
      this.clearErrors();
    },
    cancelInput() {
      this.type = this.step;
      this.inputEmail = this.email;
      this.clearErrors();
    },
    clearErrors() {
      this.errorMessageEmail = null;
      this.errorMessageCode = null;
    },
    startTimer() {
      this.delaySecond = 60;
      if (typeof this.timer !== 'undefined') {
        clearInterval(this.timer);
      }
      this.timer = setInterval(() => {
        this.delaySecond -= 1;
      }, 1000);
    },
  },
};
</script>
