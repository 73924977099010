<template>
  <VSheet
    color="tt-light-green pale"
    class="rounded-s-xl"
    data-test="widget-salary"
  >
    <VContainer class="pa-5">
      <VRow
        dense
        align="center"
      >
        <VCol
          cols="auto"
          class="mr-1"
        >
          <VIcon
            color="tt-light-green"
            data-test="widget-salary-icon"
          >
            fas fa-coins
          </VIcon>
        </VCol>
        <VCol
          v-if="days>=2"
          cols="auto"
        >
          <div
            data-test="widget-salary-left-days"
            class="tt-text-title-1"
          >
            {{ days }}
          </div>
        </VCol>
        <VCol cols="auto">
          <div
            data-test="widget-salary-text"
            class="tt-text-body-1 tt-light-mono-64--text"
          >
            <template v-if="prepayment">
              {{ $tc('pluralize.salaryPrepaid',days) }}
            </template>
            <template v-else>
              {{ $tc('pluralize.salary',days) }}
            </template>
          </div>
        </VCol>
      </VRow>
    </VContainer>
  </VSheet>
</template>

<script>
export default {
  name: 'SalaryWidget',
  props: {
    prepayment: {
      type: Boolean,
      default: true,
    },
    days: {
      type: [Number, String],
      default: 0,
    },
  },
};
</script>

<style scoped>

</style>
