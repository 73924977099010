<template>
  <VRow class="ma-0 achievement">
    <VCol
      class="pa-0"
      :cols="12"
    >
      <h2 class="tt-text-title-2">
        {{ $t('profile.achievement') }}
      </h2>
      <div
        v-if="hasAtLeastOnePicture"
        class="achievement-list d-flex flex-wrap mt-4"
      >
        <template v-for="(item, i) in list">
          <VTooltip
            v-if="item.icon"
            :key="`profile-achievement-tooltip-${i}`"
            bottom
          >
            <template #activator="{ on, attrs }">
              <div
                v-bind="attrs"
                class="achievement-item mr-6 mb-6 pa-2 d-flex flex-column justify-center align-center cursor-pointer"
                :data-test="`profile-achievement-card-${i}`"
                v-on="on"
              >
                <VImg
                  max-height="64"
                  max-width="64"
                  contain
                  :src="item.icon"
                  data-test="profile-achievement-card-img"
                  data-test-value=""
                />
                <span
                  data-test="profile-achievement-card-name"
                  class="achievement-item-name text-center tt-text-body-2 mt-2"
                >
                  {{ item.name }}
                </span>
              </div>
            </template>
            <span data-test-label="profile-achievement-description">{{ item.description }}</span>
          </VTooltip>
        </template>
      </div>
    </VCol>
  </VRow>
</template>

<script>
export default {
  name: 'ProfileAchievement',
  props: {
    meta: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    list() {
      return this.meta.achievements?.map(
        (item) => ({
          name: item.name,
          icon: item.achieved ? item.iconUrl : item.iconInactiveUrl,
          description: item.description,
        }),
      );
    },
    hasAtLeastOnePicture() {
      return this.list?.some((item) => !!item.icon);
    },
  },
};
</script>

<style lang="scss" scoped>
  .achievement-item {
    width: 152px;
    height: 152px;
    background: #F5F6F6;
    border-radius: 8px;
  }
  .achievement-item-name {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .cursor-pointer{
    cursor: pointer;
  }
</style>
