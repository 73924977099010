<template>
  <div v-if="!isMobile">
    <VContainer
      v-show="!loading"
      class="pa-0"
    >
      <VRow>
        <VCol
          v-if="userId"
          cols="auto"
          sm="1"
        >
          <BackwardButton />
        </VCol>
        <VCol
          cols="12"
          sm="8"
        >
          <VRow>
            <VCol v-if="useNewProfile && paymentInfo">
              <SalaryWidget
                :prepayment="paymentInfo.isPrepayment"
                :days="paymentInfo.leftDays"
              />
            </VCol>
            <VCol
              cols="12"
            >
              <ProfileHeader
                :level="levelName"
                :levels-completed="levelsCompleted"
                :photo-url="user.photoUrl"
                :first-name="user.firstName"
                :last-name="user.lastName"
                :position="user.staffPosition"
                :score="user.bonus"
              />
              <template v-if="!userId">
                <VDivider class="my-6" />
                <ProfileAbout
                  :meta="user"
                  :is-another-profile="!!userId"
                  @update:about="updateAbout"
                />
                <ProfileEmail
                  v-if="isRequestEmail"
                  :email="user.email"
                  :edit="isRequestEmail"
                  :step="!user.isEmailConfirmed && user.email ? 'input_code' : null"
                  @update:email="updateEmail"
                />
                <VDivider class="my-6" />
                <ProfileAchievement
                  :meta="user"
                />
                <template v-if="!useNewProfile">
                  <VDivider class="my-6" />
                  <ProfileRating
                    v-if="!hideRating"
                    :meta="user"
                    :level="levelName"
                  />
                </template>
              </template>
              <template v-else>
                <VDivider class="my-6" />
                <ProfileAbout
                  :key="`profile-about-user-${userId}`"
                  :meta="user"
                  :is-another-profile="!!userId"
                  @update:about="updateAbout"
                />
                <VDivider class="my-6" />
                <ProfileAchievement
                  :meta="user"
                />
              </template>
            </VCol>
          </VRow>
        </VCol>
      </VRow>
    </VContainer>
  </div>
  <div v-else>
    <AppBarMobile>
      <VRow align="center">
        <VCol cols="1">
          <BackButton v-if="userId" />
        </VCol>
        <VCol
          cols="10"
          class="text-center"
        >
          <h1
            data-test="profile-view-h1"
            class="tt-text-title-1 tt-light-mono-4--text"
          >
            {{ $t('menu.profile') }}
          </h1>
        </VCol>
      </VRow>
    </AppBarMobile>
    <VContainer
      v-if="!loading"
    >
      <VRow>
        <VCol v-if="useNewProfile && paymentInfo">
          <SalaryWidget
            :prepayment="paymentInfo.isPrepayment"
            :days="paymentInfo.leftDays"
          />
        </VCol>
        <VCol
          cols="12"
        >
          <ProfileHeader
            :level="levelName"
            :levels-completed="levelsCompleted"
            :photo-url="user.photoUrl"
            :first-name="user.firstName"
            :last-name="user.lastName"
            :position="user.staffPosition"
            :score="user.bonus"
          />
          <template v-if="!userId">
            <VDivider class="my-6" />
            <ProfileAbout
              :meta="user"
              :is-another-profile="!!userId"
              @update:about="updateAbout"
            />
            <ProfileEmail
              v-if="isRequestEmail"
              :email="user.email"
              :edit="isRequestEmail"
              :step="!user.isEmailConfirmed && user.email ? 'input_code' : null"
              @update:email="updateEmail"
            />
            <VDivider class="my-6" />
            <ProfileAchievement
              :meta="user"
            />
            <template v-if="!useNewProfile">
              <VDivider class="my-6" />
              <ProfileRating
                v-if="!hideRating"
                :meta="user"
                :level="levelName"
              />
            </template>
          </template>
          <template v-else>
            <VDivider class="my-6" />
            <ProfileAbout
              :key="`profile-about-user-${userId}`"
              :meta="user"
              :is-another-profile="!!userId"
              @update:about="updateAbout"
            />
            <VDivider class="my-6" />
            <ProfileAchievement
              :meta="user"
            />
          </template>
        </VCol>
      </VRow>
      <VRow v-if="!userId">
        <VCol cols="12">
          <TTBtn
            block
            dark
            color="tt-light-red reduced"
            @click="handleSignOut"
          >
            {{ $t('common.sign_out') }}
          </TTBtn>
        </VCol>
      </VRow>
    </VContainer>
  </div>
</template>

<script>
import * as snamiApi from '@/services/api/snami';
import ProfileAbout from '@/components/profile/ProfileAbout.vue';
import ProfileEmail from '@/components/profile/ProfileEmail.vue';
import BackwardButton from '@/components/shared/BackwardButton.vue';
import ProfileAchievement from '@/components/profile/ProfileAchievement.vue';
import ProfileRating from '@/components/profile/ProfileRating.vue';
import { SET_STAFF_PROPERTY, toggleSnack } from '@/plugins/vuex/mutationTypes';
import { GET_STAFF_ACTION } from '@/plugins/vuex/actionTypes';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import ProfileHeader from '@/components/profile/ProfileHeader.vue';
import { LEVEL_STATE } from '@/helpers/constants';
import SalaryWidget from '@/components/widgets/SalaryWidget.vue';
import { calculatePaymentInfo } from '@/helpers/dateHelpers';
import AppBarMobile from '@/components/app/AppBarMobile.vue';
import BackButton from '@/components/shared/BackButton.vue';
import { accessToken } from '@/services/cookies';

export default {
  name: 'ProfileView',
  components: {
    BackButton,
    AppBarMobile,
    SalaryWidget,
    ProfileHeader,
    ProfileRating,
    ProfileAchievement,
    ProfileAbout,
    ProfileEmail,
    BackwardButton,
  },

  inject: ['Names', 'glFeatures'],

  props: {
    userId: {
      type: [String, Number],
      default: null,
    },
  },

  data() {
    return {
      loading: true,
      userById: {},
      prepayment: true,
      days: 1,
    };
  },

  computed: {
    ...mapGetters('levels', ['levelList']),
    ...mapGetters('settings', ['hideRating']),
    ...mapGetters('user', ['staff', 'isRequestEmail']),
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    levelName() {
      let levelName = '';
      if (this.userId) {
        levelName = this.user?.level?.name;
      } else {
        const firstOpenedLevel = this.levelList.find((item) => item.state === LEVEL_STATE.OPENED);
        const completedLevels = this.levelList.filter((item) => item.state === LEVEL_STATE.COMPLETED);
        if (firstOpenedLevel) {
          levelName = firstOpenedLevel?.name;
        } else if (completedLevels.length) {
          levelName = completedLevels[completedLevels.length - 1].name;
        } else {
          levelName = '';
        }
      }

      return levelName;
    },
    levelsCompleted() {
      let levelsCompleted = false;
      if (this.userId) {
        // TODO Найти где в ручке получается, что пользователь завершил все задания
      } else {
        const completedLevels = this.levelList.filter((item) => item.state === LEVEL_STATE.COMPLETED);
        levelsCompleted = completedLevels.length === this.levelList.length;
      }
      return levelsCompleted;
    },
    user() {
      return !this.userId ? this.staff : this.userById;
    },
    useNewProfile() {
      return this.glFeatures('new_profile');
    },
    paymentInfo() {
      try {
        return this.calculatePaymentInfo({
          prepaymentInfo: {
            day: this.user?.prepaymentDay,
            isPrepayment: true,
          },
          paymentInfo: {
            day: this.user?.paymentDay,
            isPrepayment: false,
          },

        });
      } catch (e) {
        return false;
      }
    },
  },

  watch: {
    userId: {
      handler() {
        this.checkUserId();
      },
      immediate: true,
    },
  },
  methods: {
    calculatePaymentInfo,
    ...mapMutations([toggleSnack]),
    ...mapMutations('user', { setStaffProperty: SET_STAFF_PROPERTY }),
    ...mapActions('user', { getStaff: GET_STAFF_ACTION }),
    async checkUserId() {
      this.loading = true;
      const { userId } = this;
      try {
        if (!userId) {
          await this.getStaff();
        } else {
          await this.getUserStat(userId);
        }
      } catch (e) {
        console.error(e);
      }
      this.loading = false;
    },
    async getUserStat(id) {
      try {
        const { data } = await snamiApi.staffStatByIdGet({
          rateCount: 10,
          id,
        });
        this.userById = data;
      } catch (e) {
        await this.$router.push({ name: this.Names.R_APP_PROFILE });
        const errorMessage = e.response?.error?.message || 'Пользователь не найден';
        this.toggleSnack({
          message: errorMessage,
          status: 'error',
        });
      }
    },
    updateAbout(about) {
      try {
        this.setStaffProperty({ about });
        snamiApi.staffUpdate({ about });
      } catch (e) {
        const errorMessage = e.response?.error?.message || this.$t('request_error');
        this.toggleSnack({
          message: errorMessage,
          status: 'error',
        });
      }
    },
    async updateEmail(email) {
      this.setStaffProperty({
        isEmailConfirmed: true,
        email,
      });
      try {
        await this.getStaff();
      } catch (e) {
        console.error(e);
      }
    },
    clearSkippedTasks() {
      localStorage.removeItem('skippedTaskItem');
    },
    handleSignOut() {
      accessToken.remove();
      this.clearSkippedTasks();
      window.location.replace('/');
    },
  },
};
</script>
<style lang="scss">
</style>
